import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { share } from 'rxjs/operators';
import { LocalDataService } from './local-data.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    protected ipChangeObservable: Observable<string>;
    protected ipChangeSubscriber: Subscriber<string>;

    private ip: string;
    private hostessName: string;
    private listItemPerPage: number;

    constructor(private localData: LocalDataService) {
        this.ipChangeObservable = new Observable<any>((observer) => {
            this.ipChangeSubscriber = observer;
        }).pipe(share());
        this.ipChangeObservable.subscribe();
        this.localData.getIP().then((res) => {
            this.ip = res;
        });
        this.localData.getHostessName().then((res) => {
            this.hostessName = res;
        });
        this.localData.getListItemPerPage().then((res) => {
            this.listItemPerPage = res;
        });
    }

    onIPChange() {
        return this.ipChangeObservable;
    }

    saveIP(ip: string) {
        this.localData.setIP(ip).then(() => {
            this.ip = ip;
            this.ipChangeSubscriber.next(ip);
        });
    }

    getIP() {
        return this.ip;
    }

    saveHostessName(name: string) {
        this.localData.setHostessName(name).then(() => {
            this.hostessName = name;
        });
    }

    getHostessName() {
        return this.hostessName;
    }

    saveListItemPerPage(count: number) {
        this.localData.setListItemPerPage(count).then(() => {
            this.listItemPerPage = count;
        });
    }

    getListItemPerPage() {
        return this.listItemPerPage;
    }
}
