import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import {
  ApiService,
  AppConfig,
  AppConfigService,
  DefaultErrorHandler,
  EclickCoreModule,
  ErrorInterceptor
} from '@eclick/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LocalDataService } from './core/services/local-data.service';
import { SettingsService } from './core/services/settings.service';
import { EventApiService } from './core/http/api.service';
import { EventErrorInterceptor } from './core/interceptors/error.interceptor';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { ServiceWorkerModule } from '@angular/service-worker';

export const coreConfig: AppConfig = {
  apiUrl: 'https://belepteto.eclick.app/api/',
  apiVersion: '1.0',
  supportedLanguages: ['hu'],
  dbPrefix: 'event'
};

export const createErrorInterceptor = (errorHandler: DefaultErrorHandler) => {
  return (new ErrorInterceptor(coreConfig, errorHandler));
};

export const initStorage = (localData: LocalDataService) => async () => localData.onReady();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    EclickCoreModule.forRoot(coreConfig),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // TODO: Upgrade Firestore
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: ApiService,
      useFactory: (config: AppConfig, http: HttpClient, localData: LocalDataService, settings: SettingsService) => {
        return new EventApiService(config, http, localData, settings);
      },
      deps: [AppConfigService, HttpClient, LocalDataService, SettingsService]
    },
    {
      provide: HTTP_INTERCEPTORS, useFactory: createErrorInterceptor, deps: [DefaultErrorHandler], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initStorage, deps: [LocalDataService], multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
