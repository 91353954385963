import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, ApiResponse, ApiService, AppConfig, AppConfigService, ErrorType } from '@eclick/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, publish, refCount, timeout } from 'rxjs/operators';
import { LocalDataService } from '../services/local-data.service';
import { SettingsService } from '../services/settings.service';

@Injectable()
export class EventApiService extends ApiService {

    constructor(@Inject(AppConfigService) config: AppConfig, http: HttpClient, localData: LocalDataService,
                settingsService: SettingsService) {
        super(config, http);

        // Load from local data
        localData.getIP().then((res) => {
            this.setBaseUrl(res);
        });

        // Listen for changes
        settingsService.onIPChange().subscribe((res) => {
            this.setBaseUrl(res);
        });
    }

    private setBaseUrl(url: string) {
        // if it has any value, override
        if (url != null && url !== '') {
            this.BASE_URL = url;
        } else {
            this.BASE_URL = this.config.apiUrl;
        }
        console.log('api url was set to', this.BASE_URL);
    }


    public override post<T>(action: string, body?: any, headers?: any, timeoutValue: number = 10000): Observable<ApiResponse<T>> {
        return this.http.post<ApiResponse<T>>(this.BASE_URL + action, body).pipe(timeout(timeoutValue), publish(),
            refCount(), catchError((errorResponse) => {
                if (errorResponse instanceof TimeoutError) {
                    const timeoutError: any = errorResponse.message;
                    const timeoutErrorResponse: ApiError = {type: ErrorType.Timeout, errors: timeoutError};
                    return throwError(timeoutErrorResponse);
                }
                return throwError(errorResponse);
            }));
    }
}
