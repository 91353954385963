import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StorageKeys } from '../helpers/storage-keys';
import { of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  private storage: Storage | null = null;

  private storageInitialized = false;

  private onReadySubject = new Subject<boolean>();

  constructor(private ionicStorage: Storage) {
    this.init();
  }

  async init() {
    console.log('storage init');
    this.storage = await this.ionicStorage.create();
    console.log('ionic storage created');
    this.storageInitialized = true;
    this.onReadySubject.next(true);
    console.log('ready subject next')
  }

  onReady() {
    console.log('on ready called')
    if (this.storageInitialized) {
      console.log('already initialized')
      return of(true);
    }
    console.log('return ready subject');
    return this.onReadySubject.asObservable();
  }

  getIP() {
    return this.storage.get(StorageKeys.IP);
  }

  setIP(ip: string) {
    return this.storage.set(StorageKeys.IP, ip);
  }

  getHostessName() {
    return this.storage.get(StorageKeys.HostessName);
  }

  setHostessName(name: string) {
    return this.storage.set(StorageKeys.HostessName, name);
  }

  getListItemPerPage() {
    return this.storage.get(StorageKeys.ListItemPerPage);
  }

  setListItemPerPage(count: number) {
    return this.storage.set(StorageKeys.ListItemPerPage, count);
  }
}
